<template>
  <CRow class="">
    <CCol col="12" class="mb-2">
      <hr>
    </CCol>
    <CCol lg="5">
      <label class="mb-1 big" v-translate translate-context="event_wizard.label">Upload logo</label>
      <p>
        <translate translate-context="event_wizard.subtitle">
          Enter your logo in the dedicated field to personalize your event photos
        </translate>
      </p>
    </CCol>
    <CCol lg="7">
      <PUploader
          :params="uploadParams.copyright"
          :get_intent="get_upload_intent"
          :value.sync="form.logo"
          :help_text="$pgettext('uploader.help','Allowed formats: .jpeg .png')"
          @uploaded="newLogoUploaded"
      />
    </CCol>
    <CCol col="12" class="mt-3 mb-2">
      <hr>
    </CCol>
    <CCol lg="5">
      <label class="mb-1 big" v-translate translate-context="event_wizard.label">Logo position</label>
    </CCol>
    <CCol lg="7">
      <div class="btn-group-squared btn-group-toggle btn-group-squared__logo_position" style="max-width: 200px">
        <label class="btn btn-outline-secondary btn-toggle mb-2" v-for="(position, index) in logoPositions"
               :key="index"
               :class="{'active': position.value === form.position}" :style="position.style">
          <input type="radio" name="logo-size" :id="`option-${index}`"
                 v-model="form.position"
                 :value="position.value"
                 :checked="position.value === form.position"
                 @change="save">
          <CIcon size="xl" :class="{'xxl': !!position.style}" :name="position.iconName" class="m-0"/>
        </label>
      </div>
    </CCol>
    <CCol col="12" class="mt-3 mb-2">
      <hr>
    </CCol>
    <CCol lg="5">
      <label class="mb-1 big" v-translate translate-context="event_wizard.label">Logo size</label>
    </CCol>
    <CCol lg="7">
      <div class="btn-group-squared btn-group-squared__logo_sizes btn-group-toggle">
        <label class="btn btn-outline-secondary btn-toggle mb-2" v-for="(size, index) in logoSizes" :key="index"
               :class="{'active': size.value === form.size}">
          <input type="radio" name="logo-size"
                 :id="`option-${index}`"
                 v-model="form.size"
                 :value="size.value"
                 :checked="size.value === form.size"
                 @change="save">
          <CIcon size="custom-size" :width="size.iconSize" :height="size.iconSize" name="cipSquare"/>
        </label>
      </div>
    </CCol>
    <CCol col="12" class="mt-3 mb-2">
      <hr>
    </CCol>

    <CCol lg="5">
      <label class="mb-1 big" v-translate translate-context="event_wizard.label">Background color</label>
    </CCol>
    <CCol lg="7">
      <PColorPicker :color="form.bg_color"
                    :alpha="form.bg_opacity"
                    @change="onChangeColor"/>
    </CCol>


  </CRow>
</template>

<script>
import {upload} from "@/domain/eventWizard/upload";
import {DELETE_KEY, isUploadIntent} from "@/domain/core/components/uploader";
import PUploader from "@/domain/core/components/PUploader.vue";
import PColorPicker from "@/domain/core/components/PColorPicker.vue";

const logoSizes = [
  {
    iconSize: '8',
    value: 'small'
  },
  {
    iconSize: '18',
    value: 'medium'
  },
  {
    iconSize: '28',
    value: 'large'
  },
  {
    iconSize: '44',
    value: 'original'
  }
]

const logoPositions = [
  {
    iconName: 'cipTl',
    value: 'top-left'
  },
  {
    iconName: 'cipTc',
    value: 'top-center',
    style: 'padding: 0 0 12px;'
  },
  {
    iconName: 'cipTr',
    value: 'top-right'
  },
  {
    iconName: 'cipBl',
    value: 'bottom-left'
  },
  {
    iconName: 'cipBc',
    value: 'bottom-center',
    style: 'padding: 12px 0 0;'
  },
  {
    iconName: 'cipBr',
    value: 'bottom-right'
  },
]

export default {
  name: "SimpleLogoEditor",
  components: {PColorPicker, PUploader},
  props: {
    images: {
      type: [Object, Array],
      required: false
    },
    get_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
    advanced: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['save', 'updatePreview'],
  computed: {
    uploadParams() {
      return upload
    },
    logoPositions() {
      return logoPositions
    },
    logoSizes() {
      if (this.advanced)
        return logoSizes
      return logoSizes.filter(s => s.value !== 'original')
    },

  },
  data() {
    return {
      colorModel: {
        hex: '#ff0000',
        a: 1,
      },
      form: {
        logo: null,
        position: 'bottom-center',
        size: 'medium',
        bg_color: null,
        bg_opacity: 1,
        tags: []
      },
      logo_url: null,
      default: {
        position: 'bottom-center',
        size: 'medium',
        bg_color: null,
        bg_opacity: 1,
        tags: []
      }
    }
  },
  watch: {
    images: function () {
      this.init()
    }
  },

  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.logo_url = ''
      if (!this.images.length) {
        this.form = {...this.default}
        this.previewFieldUpdated()
        return
      }

      this.form = {...this.default, ...this.images[0]}
      if (this.form.logo && this.form.logo !== DELETE_KEY)
        this.logo_url = this.form.logo
      this.previewFieldUpdated()
    },
    async newLogoUploaded() {
      await this.$nextTick()
      if (this.form.logo === DELETE_KEY)
        this.logo_url = ''
      else {
        this.logo_url = await this.get_upload_intent_public_url(this.form.logo)
      }
      this.save()
    },
    onChangeColor(color) {
      this.form.bg_color = color.hex
      this.form.bg_opacity = color.a * 100
      this.save()
    },

    save() {
      this.previewFieldUpdated()
      this.$emit('save', [
        {...this.form}
      ])
    },
    previewFieldUpdated() {
      if (!this.logo_url) return this.$emit('updatePreview', [])
      this.$emit('updatePreview', [{
        key: 'landscape',
        icon: 'landscape',
        width: 1620,
        height: 1080,
        logo_intent: isUploadIntent(this.form.logo) ? this.form.logo : null,
        logo_id: isUploadIntent(this.form.logo) ? null : this.form.id,
        position: this.form.position,
        size: this.form.size,
        bg_color: this.form.bg_color,
        bg_opacity: this.form.bg_opacity,
      }, {
        key: 'portrait',
        icon: 'portrait',
        width: 1080,
        height: 1620,
        logo_intent: isUploadIntent(this.form.logo) ? this.form.logo : null,
        logo_id: isUploadIntent(this.form.logo) ? null : this.form.id,
        position: this.form.position,
        size: this.form.size,
        bg_color: this.form.bg_color,
        bg_opacity: this.form.bg_opacity,
      }])
    },
  }
}
</script>