import fileUtils from "@/domain/core/utils/fileUtils";

export const props = {
    params: {
        /**
         * {
         *     allowed_extensions: ['jpg','png','jpeg','mp4'],
         *
         *     max_image_size: 4000000 # 4mb
         *     input_image_size: {
         *         minWidth: 200,
         *         maxWidth: 200,
         *         minHeight: 200,
         *         maxHeight: 200
         *     },
         *     compression: {
         *         https://github.com/fengyuanchen/compressorjs/blob/main/README.md
         *         // minWidth, maxWidth, minHeight, maxHeight, width, height
         *         quality: 0.9,
         *         mimeType: 'image/jpeg',
         *         convertSize: 4000000, #4mb
         *         extension: 'jpg'
         *     }
         *
         *     max_video_size: 50000000 # 50mb
         * }
         */
        type: Object,
        required: false
    }, sorting: {
        type: Boolean, default: false
    }, multiple: {
        type: Boolean, default: false
    }, allow_delete: {
        type: Boolean, default: true
    }, get_intent: {
        type: Function, required: true
    }
}

export const methods = {
    isValidFileUrl(url) {
      if (typeof url !== 'string') return false
      if (url === DELETE_KEY) return  false
      return !parseInt(url);
    }, async doUpload(file, progress) {
        progress.inProgress = true
        progress.progress = 0

        const intent = await this.get_intent(file.name)
        await fileUtils.uploadToS3({
            file: file, intent: intent, onProgress: this.onProgress.bind(this, progress)
        })
        progress.inProgress = false
        return intent.intent_id
    }, onProgress(progress, uploaded) {
        progress.progress = Math.round(uploaded.loaded / uploaded.total * 100)
    }, async compressImage(file, extension) {
        const compressedFile = await fileUtils.compress(file,this.params.compression)
        const file_name_without_extension = file.name.split('.').slice(0, -1).join('.')
        return new File([compressedFile], `${file_name_without_extension}.${extension}`, {type: compressedFile.type})
    }
}

export const DELETE_KEY = 'delete'

export const mapImageList = async (images, resolve_function) => {
    if (!images) return []
    return Promise.all(images
        .filter(f => {
            if (!f.file) return false
            if (f.file instanceof File && !f.intent) return false
            return true
        })
        .map(async f => {
            if (f.file instanceof File) {
                if (resolve_function) return await resolve_function(f.intent)
                return URL.createObjectURL(f.file)
            }
            return f.file
        }));
}
export const isUploadIntent = (logo) => {
    if (!logo) return false
    if (logo === DELETE_KEY) return false
    return Number.isInteger(parseInt(logo))
}