<template>
  <div>
    <PFilePreview
        v-if="existing_file"
        :file="existing_file"
        :filename="existing_filename"
        :allow_delete="allow_delete"
        @delete="deleteFile"
    />

    <div class="mb-1" v-if="!existing_file && !new_file || has_error">
      <PFileDrop @dropped="onFileChosen"/>
      <div v-if="help_text" class="text-help mt-1">{{
          help_text
        }}
      </div>
    </div>

    <PFileUploader
        v-if="new_file"
        :get_intent="get_intent"
        :allow_delete="allow_delete"
        :params="params"
        :input="new_file"
        @intent="$emit('update:value',$event)"
        @uploaded="$emit('uploaded',$event)"
        @error="has_error = true"
        @delete="deleteFile"
    />

    <PCropper
        ref="modalCropper"
        :aspect-ratio="cropper_aspect_ratio"
        v-if="use_cropper && new_file"
        :file="new_file"
        @file-edited="onFileEdited"
        @dismiss="onCropperDismiss"/>
  </div>
</template>

<script>
import PFileDrop from "@/domain/core/components/upload/PFileDrop.vue";
import {props as uploader_props, methods as uploader_methods, DELETE_KEY} from "@/domain/core/components/uploader";
import PFileUploader from "@/domain/core/components/upload/PFileUploader.vue";
import PFilePreview from "@/domain/core/components/upload/PFilePreview.vue";
import PCropper from "@/domain/core/components/upload/PCropper.vue";
import fileUtils from "@/domain/core/utils/fileUtils";

export default {
  name: "PUploader",
  components: {PCropper, PFilePreview, PFileUploader, PFileDrop},
  props: {
    help_text: {
      type: String,
      required: false
    },
    value: {
      type: [String, Number],
      required: false
    },
    filename: {
      type: String,
      required: false
    },
    preview: {
      type: String,
      required: false
    },
    use_cropper: {
      type: Boolean,
      default: false
    },
    cropper_aspect_ratio: {
      type: [Number, null],
      required: false
    },
    ...uploader_props
  },
  emit: ['update:value', 'uploaded'],
  mounted() {
    this.setExistingFile(this.value)
  },
  data() {
    return {
      new_file: null,
      existing_file: null,
      existing_filename: null,
      has_error: false,
    }
  },
  watch: {
    value: function (newVal) {
      if (!newVal || newVal === DELETE_KEY) {
        this.existing_file = null
        this.new_file = null
      } else
        this.setExistingFile(newVal)
    }
  },
  methods: {
    setExistingFile(file) {
      if (!this.new_file && this.preview && this.preview.length) {
        this.existing_file = this.preview
      } else {
        if (!this.isValidFileUrl(file)) return
        this.existing_file = file
        this.existing_filename = this.filename
        this.new_file = null
      }
      if (!this.existing_filename)
        this.existing_filename = this.value.split('?').shift().split('/').pop()
    }, async onFileChosen(file) {
      if (file) {
        this.setFile(file);
        if (this.use_cropper) {
          if (!fileUtils.isImage(file)) return
          await this.$nextTick()
          await this.$refs.modalCropper.open()
        }
      }
    }, setFile(file) {
      this.has_error = false
      this.new_file = file
      this.existing_file = null
    }, onFileEdited(file) {
      this.setFile(file)
    }, onCropperDismiss() {
      this.new_file = null
    }, deleteFile() {
      this.new_file = null
      this.$emit('update:value', DELETE_KEY)
      this.$emit('uploaded', '')
    },
    ...uploader_methods
  }
}
</script>