import {translate} from "vue-gettext";

const {gettext: $gettext} = translate;

export default {
    domain: 'domain',
    platform: 'platform',
    media_type: 'media_type',
    photographer: 'photographer',
    gallery: 'g',
    code_release: 'code_release',
    source: 'source',

    options() {
        return [
            {
                id: this.domain,
                name: $gettext('Domain')
            },
            {
                id: this.platform,
                name: $gettext('Platform')
            },
            {
                id: this.media_type,
                name: $gettext('Media Type')
            },
            // {
            //     id: this.photographer,
            //     name: $gettext('Photographers')
            // },
            {
                id: this.gallery,
                name: $gettext('Galleries')
            },
            {
                id: this.source,
                name: $gettext('Source')
            },
            // {
            //     id: this.code_release,
            //     name: $gettext('Code Release')
            // },

        ]
    },

    media_types: {
        landscape: 'media_type:landscape',
        portrait: 'media_type:portrait',
    }
}