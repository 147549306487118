<template>
  <div class="embed-responsive preview-iframe-w">
    <iframe ref="iframe" @load="updateData" :src="src" class="embed-responsive-item"/>
  </div>

</template>

<script>
export default {
  name: "PIframe",
  props: {
    data: {
      type: Object,
      required: true
    },
    route: {
      type: Object,
      required: true
    },
    src: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      iframe: null
    }
  },
  watch: {
    data: function () {
      this.updateData()
    }
  },
  methods: {
    updateData() {
      if (!this.$refs.iframe) return;
      this.$refs.iframe.contentWindow.postMessage({
        route: this.route,
        data: this.data,
      }, '*')
    }
  },
}
</script>