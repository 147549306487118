<template>
  <div class="preview-w">
    <div class="preview" :class="{'off': !showPreview, 'on-mobile': showPreviewMobile}">
      <div class="preview__body">
        <div class="preview-content" :class="{'overflow-hidden': overflowHidden}">
          <div class="device-head"></div>
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PreviewBar",
  data() {
    return {
      showPreview: true,
      showPreviewMobile: false
    }
  },
  props: {
    overflowHidden: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    open(isMobile) {
      if (isMobile) {
        this.showPreviewMobile = true;
      }else {
        this.showPreview = true;
      }
      this.$emit('toggle', true)
    },
    close() {
      this.showPreview = false;
      this.showPreviewMobile = false;

      this.$emit('toggle', false)
    }
  }
}
</script>