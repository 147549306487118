<template>

  <CRow class="mb-3">
    <CCol sm="12">
      <h5 v-translate>Custom logo</h5>
      <div v-for="media in medias" :key="media.id" class="sub-section">
        <CRow>
          <CCol sm="12">
            <SubLogo
                :get_upload_intent_public_url="get_upload_intent_public_url"
                :get_upload_intent="get_upload_intent"
                :media="media"
                @update="onMediaSave(media.id, $event)"
                @delete="onMediaDelete(media.id)"
            />
          </CCol>
        </CRow>
      </div>

    </CCol>
    <CCol sm="12" class="py-3">
      <CButton color="primary" @click="addMedia" v-translate>Add media</CButton>
    </CCol>
  </CRow>

</template>

<script>
import {cloneDeep} from "lodash";
import SubLogo from "@/domain/logoSet/sub/SubLogo.vue";
import {v4 as uuid} from 'uuid';
import {DELETE_KEY, isUploadIntent} from "@/domain/core/components/uploader";

export default {
  name: "CustomEditor",
  components: {SubLogo},
  props: {
    images: {
      type: [Object,Array],
      required: false
    },
    get_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
  },
  emits: ['save', 'updatePreview'],
  data() {
    return {
      medias: [],
      empty: {
        id: null,
        position: 'bottom-center',
        size: 'medium',
        bg_color: '#ffffff',
        bg_opacity: 0,
        tags: []
      }
    }
  },
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.medias = []
      for (let i = 0; i < this.images.length; i++) {
        let media = this.images[i]
        this.medias.push(cloneDeep(media))
      }
      this.emitPreview()
    },
    addMedia() {
      this.medias.push({
        ...this.empty,
        isNew: true,
        id: uuid()
      })
    },
    onMediaSave(id, media) {
      this.medias = this.medias.map(m => {
        if (m.id === id) {
          return media
        }
        return m
      })
      this.save()
    },
    onMediaDelete(id) {
      this.medias = this.medias.filter(m => m.id !== id)
      this.save()
    },
    emitPreview() {
      this.$emit('updatePreview', this.medias
          .filter(media => media.logo !== null && media.logo !== DELETE_KEY)
          .map(media => {
            return {
              ...media,
              key: media.id,
              width: 1620,
              height: 1080,
              logo_intent: isUploadIntent(media.logo) ? media.logo : null,
              logo_id: isUploadIntent(media.logo) ? null : media.id,
              icon: 'landscape'
            }
          }))
    },
    save() {
      this.emitPreview()
      this.$emit('save', this.medias.map(media => {
        return {
          ...media,
          id: media.isNew ? null : media.id
        }
      }))
    }
  }
}
</script>