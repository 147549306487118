<template>
  <div v-c-clickaway="hidePicker">
    <div class="d-flex position-relative">
      <CButton ref="pickerButton" class="form-control form-color" @click="togglePicker">
        <span class="color" :style="colorStyle" :class="{'transparent': !data.hex}"></span>
      </CButton>
      <CInput type="text"
              addInputClasses="form-color-picker-value"
              v-model="data.hex"
              @input="onUpdate"
              @click="togglePicker"
      />
      <div class="color-picker" :class="pickerPosition" ref="colorPicker" v-if="showPicker">
        <color-picker :disable-alpha="disableAlpha" v-model="data" @input="onUpdate"/>
      </div>
    </div>
  </div>
</template>

<script>
import Chrome from 'vue-color/src/components/Chrome'
import debounce from 'lodash/debounce';

export default {
  name: "PColorPicker",
  components: {'color-picker': Chrome},
  emits: ['change'],
  data() {
    return {
      pickerPosition: 'bottom',
      showPicker: false,
      data: {
        hex: this.color,
        a: this.alpha
      },
    };
  },
  props: {
    color: {
      type: String,
      default: '#000000',
      required: false
    },
    alpha: {
      type: Number,
      default: 1,
      required: false
    },
    disableAlpha: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colorStyle() {
      return {
        backgroundColor: this.data.hex,
        opacity: this.data.a
      }
    },
    display() {
      return {
        'd-flex': this.block,
        'd-inline-flex': !this.block
      }
    }
  },

  methods: {
    onUpdate: debounce(function () {
      this.data = {...this.data}
      this.$emit('change', this.data)
    }, 200),
    async togglePicker() {
      this.showPicker = !this.showPicker
      await this.$nextTick()
      if(this.showPicker) this.setPickerPosition();
    },
    hidePicker() {
      this.showPicker = false;
    },
    setPickerPosition() {
      const buttonRect = this.$refs.pickerButton.getBoundingClientRect()
      const pickerHeight = this.$refs.colorPicker.offsetHeight

      // Check if there is enough space below the button for the picker
      if (window.innerHeight - buttonRect.bottom < pickerHeight) {
        this.pickerPosition = 'top'
      } else {
        this.pickerPosition = 'bottom'
      }
    }
  }
}
</script>
