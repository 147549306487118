<template>
  <div class="preview__step-logo">
    <div class="d-flex h-100 justify-content-center align-items-center">
      <CImg fluid block class="mx-auto" :src="logoUrl" v-if="logo && logoUrl"/>
      <img class="mx-auto d-block img-fluid" v-else
           :src="require(`@/assets/images/event_wizard/preview/logo-landscape.jpg`)" alt=""/>
    </div>
    <ul class="list-inline icons">
      <li class="list-inline-item" v-for="(logo,i) in data" :key="logo.key">
        <CLink :class="{'selected': selected===i}" v-if="logo.icon" @click="selected = i">
          <CIcon :name="`cip-${logo.icon}`" size="xl"/>
        </CLink>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "PreviewLogo",
  props: {
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      selected: 0,
      logoUrl: null,
    }
  },
  watch: {
    data: function () {
      this.init()
    },
    logo: function () {
      this.fetchNewLogoUrl()
    }
  },
  computed: {
    logo() {
      if (this.data.length <= 0) return null
      return this.data[this.selected]
    },
  },
  methods: {
    ...mapActions('logoSet', [
      'getPreviewUrl'
    ]),
    init() {
      if (this.selected >= this.data.length) this.selected = 0
    },
    async fetchNewLogoUrl() {
      this.logoUrl = null
      if (!this.logo || !this.logo.key) return
      this.logoUrl = await this.getPreviewUrl(this.logo)
    }
  }
}
</script>