<template>
  <div>
    <div v-if="file && !error">
      <PFilePreview :sorting="sorting" :file="file_path" :multiple="multiple" :filename="file.name" :allow_delete="allow_delete" @delete="$emit('delete')">
        <template v-slot:footer-preview>
          <CProgress v-if="upload.inProgress" class="progress-xs p-uploader__progress" :precision="1"
                     :value="upload.progress"/>
        </template>
      </PFilePreview>
    </div>
    <div class="d-block invalid-feedback" v-if="error">{{ error }}</div>
  </div>

</template>

<script>
import PFilePreview from "@/domain/core/components/upload/PFilePreview.vue";
import {props as uploader_props, methods as uploader_methods} from "@/domain/core/components/uploader";
import {ValidationError} from "@/domain/core/exception/exceptions";
import fileUtils from "@/domain/core/utils/fileUtils";


export default {
  name: "PFileUploader",
  components: {PFilePreview,},
  props: {
    input: {
      type: [File],
      required: true
    },
    ...uploader_props
  },
  data() {
    return {
      file: null,
      error: null,
      validationRules: [],
      upload: {
        inProgress: false,
        progress: 0,
      },
    }
  },
  watch: {
    input: function () {
      this.uploadFile()
    }
  },
  emit: ['uploaded', 'intent', 'delete', 'error'],
  async mounted() {
    await this.uploadFile()
  },
  computed: {
    file_path() {
      if (!this.file) return null
      return URL.createObjectURL(this.file)
    },
  },
  methods: {
    async uploadFile() {
      this.validationRules = fileUtils.getValidationRules(this.params)
      this.error = null

      try {
        await fileUtils.validate(this.input, this.validationRules)
        if (this.params.compression && fileUtils.isImage(this.input)) {
          this.file = await this.compressImage(this.input, this.params.compression.extension ?? 'jpg')
        } else {
          this.file = this.input
        }
      } catch (e) {
        this.$emit('error', e)
        return this.error = e
      }

      try {
        const intent = await this.doUpload(this.file, this.upload)
        this.$emit('uploaded', URL.createObjectURL(this.file))
        this.$emit('intent', intent)
      } catch (e) {
        if (e instanceof ValidationError) {
          if (e.message.file_name)
            e.message = e.message.file_name[0]
          this.error = e.message
          this.$emit('error', e.message)
          this.upload.inProgress = false
        } else {
          throw e
        }
      }
    },
    ...uploader_methods
  }
}
</script>

<style scoped>

</style>