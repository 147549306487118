<template>
  <CModal :show.sync="show" centered :close-on-backdrop="false" class="modal-cropper">
    <template #header>
      <div>
        <div class="d-flex">
          <CIcon name="cipUpload2" size="custom-size" width="36" height="36"
                 class="mr-3 icon-circle icon-circle--primary"/>
          <div class="align-self-center">
            <h5 class="modal-title" v-translate translate-context="event_wizard.cropper.title">Edit image</h5>
            <p class="m-0" v-translate translate-context="event_wizard.cropper.subtitle">For best results, use a .PNG or
              .JPG of 300x300 px</p>
          </div>
        </div>
      </div>
      <CButtonClose @click="dismiss"/>
    </template>

    <PComponentLoader :loading="loading">
      <cropper ref="cropper"
               :src="file_url"
               :min-height="100"
               :min-width="100"
               :resize-image="resizeImage"
               :background-class="backgroundClass"
               :foreground-class="foregroundClass"
               :stencil-props="stencilProps"
               :stencil-size="stencilSize"
               :canvas="canvas"
               :debounce="false"
               :transitions="false"
               image-restriction="stencil"
               stencil-component="circle-stencil"
               @change="onChange"
               style="height: 400px"
      />
      <div>
        <div class="tools mt-3 d-flex align-items-start">
          <div class="" style="width:65%">
            <label class="font-weight-medium" v-translate translate-context="event_wizard.cropper">Zoom</label>
            <PCropperNavigation :zoom="zoom" @change="onZoom" style="margin-top: 12px"/>
          </div>
          <div class="ml-4">
            <label class="font-weight-medium" v-translate translate-context="event_wizard.cropper">Background</label>
            <PColorPicker disable-alpha :color="colorBg" @change="color => colorBg = color.hex"/>
          </div>
        </div>

      </div>
    </PComponentLoader>

    <template #footer>
      <div class="d-flex w-100">
        <div class="flex-grow-1 mr-1">
          <CButton block @click="dismiss"
                   color="secondary"
                   v-translate translate-context="event_wizard.cropper">Close
          </CButton>
        </div>
        <div class="flex-grow-1 text-center ml-1">
          <CButton block
                   @click="onSet"
                   :disabled="!fileEdited"
                   color="primary">
            <span v-translate translate-context="event_wizard.cropper">Set</span>
          </CButton>
        </div>
      </div>
    </template>
  </CModal>
</template>

<script>

import PComponentLoader from "@/domain/core/components/PComponentLoader.vue";
import PCropperNavigation from "@/domain/core/components/upload/PCropperNavigation.vue";
import PColorPicker from "@/domain/core/components/PColorPicker.vue";
import {beforeEach} from "@vue/cli-plugin-e2e-nightwatch/generator/template/tests/e2e/specs/test-with-pageobjects";

export default {
  name: "PCropper",
  components: {PColorPicker, PCropperNavigation, PComponentLoader},
  emits: ['file-edited', 'dismiss'],
  data() {
    return {
      show: false,
      loading: true,
      fileEdited: null,
      zoom: 0,
      colorBg: null,
      fillColor: 'transparent',
    }
  },
  props: {
    file: {
      type: [File],
      required: true
    },
    aspectRatio: {
      type: [Number, null],
      required: false,
      default: null
    },
  },
  watch: {
    colorBg: {
      handler() {
        this.fillColor = this.colorBg
        this.changeBgColor(this.fillColor);
        this.refresh()
      }
    }
  },
  computed: {
    file_url() {
      if (!this.file) return null
      return URL.createObjectURL(this.file)
    },
    is_png() {
      return this.file.type === 'image/png'
    },
    canvas() {
      return {
        fillColor: this.fillColor,
        minHeight: 300,
        minWidth: 300,
        maxWidth: 300,
        maxHeight: 300,
      }
    },
    resizeImage() {
      return {
        touch: true,
        wheel: {
          ratio: 0.1
        },
        adjustStencil: true
      }
    },
    stencilProps() {
      return {
        handlers: {},
        lines: {
          north: false,
          west: false,
          south: false,
          east: false,
        },
        linesClasses: {
          default: 'line-stencil',
        },
        movable: false,
        resizable: false,
        scalable: false,
        aspectRatio: this.aspectRatio,
      }
    },
    backgroundClass() {
      let classes = ['bg-cropper'];
      if (this.is_png && !this.colorBg) classes.push('bg-transparent')
      if (this.colorBg) classes.push('bg-color');
      return classes.join(' ');
    },
    foregroundClass() {
      let classes = ['fg-cropper'];
      if (this.is_png && !this.colorBg) classes.push('fg-transparent')
      if (this.colorBg) classes.push('fg-color');
      return classes.join(' ');
    }
  },
  methods: {
    beforeEach,
    changeBgColor(color) {
      this.$el.querySelector('.bg-cropper').style.backgroundColor = color
      this.$el.querySelector('.bg-cropper').style.backgroundImage = 'none'
      //this.$el.querySelector('.fg-cropper').style.backgroundColor = 'transparent'
    },
    refresh() {
      this.$refs.cropper.refresh()
    },
    async open() {
      this.show = true
      await this.$nextTick()
      this.loading = false
    },
    dismiss() {
      this.colorBg = null
      this.$emit('dismiss')
      this.show = false
    },
    defaultSize({imageSize, visibleArea}) {
      return {
        width: (visibleArea || imageSize).width,
        height: (visibleArea || imageSize).height,
      };
    },
    stencilSize({boundaries}) {
      return {
        width: boundaries.width * 0.88,
        height: boundaries.height * 0.88,
      }
    },
    async onSet() {
      await this.$emit('file-edited', this.fileEdited)
      this.show = false
    },

    updateZoom() {
      const cropper = this.$refs.cropper;
      if (cropper) {
        const {coordinates, imageSize} = cropper
        if (imageSize.width / imageSize.height > coordinates.width / coordinates.height) {
          // Determine the position of slider bullet
          // It's 0 if the stencil has the maximum size and it's 1 if the has the minimum size
          this.zoom =
              (cropper.imageSize.height - cropper.coordinates.height) /
              (cropper.imageSize.height - cropper.sizeRestrictions.minHeight)
        } else {
          this.zoom =
              (cropper.imageSize.width - cropper.coordinates.width) /
              (cropper.imageSize.width - cropper.sizeRestrictions.minWidth)
        }
      }
    },

    onChange({coordinates, canvas,}) {
      this.updateZoom()
      canvas.toBlob((blob) => {
        if (blob) {
          this.fileEdited = new File([blob], this.file.name, {
            type: this.file.type,
            lastModified: Date.now(),
          })
        }
      }, this.file.type, 1)
    },
    onZoom(value) {
      const cropper = this.$refs.cropper;
      if (cropper) {
        if (cropper.imageSize.height < cropper.imageSize.width) {
          const minHeight = cropper.sizeRestrictions.minHeight;
          const imageHeight = cropper.imageSize.height;
          // Determine the current absolute zoom and the new absolute zoom
          // to calculate the sought relative zoom value
          cropper.zoom(
              (imageHeight - this.zoom * (imageHeight - minHeight)) /
              (imageHeight - value * (imageHeight - minHeight))
          );
        } else {
          const minWidth = cropper.sizeRestrictions.minWidth;
          const imageWidth = cropper.imageSize.width;
          cropper.zoom(
              (imageWidth - this.zoom * (imageWidth - minWidth)) /
              (imageWidth - value * (imageWidth - minWidth))
          );
        }
      }
    },
  }
}
</script>
