const defaultParams = {
    allowed_extensions: ALLOWED_IMAGE_EXTENSIONS,
}

const ALLOWED_IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png']
const ALLOWED_VIDEO_EXTENSIONS = ['mp4', 'mov', 'm4v']

export const upload = {
    logo: {
        ...defaultParams,
        input_image_size: {minWidth: 200, minHeight: 200, aspectRatio: 1},
    },
    banner: {
        ...defaultParams,
        allowed_extensions: [ ...ALLOWED_IMAGE_EXTENSIONS,'gif'],
    },
    copyright: {
        ...defaultParams,
    },
    gallery: {
        ...defaultParams,
        compression: {maxWidth: 6000, maxHeight: 4000},
        max_video_size: 50000000, // 50mb
        allowed_extensions: [...ALLOWED_VIDEO_EXTENSIONS, ...ALLOWED_IMAGE_EXTENSIONS],
    },
    galleryPicaManager: {
        ...defaultParams,
        compression: {maxWidth: 6000, maxHeight: 4000},
        allowed_extensions: [...ALLOWED_VIDEO_EXTENSIONS, ...ALLOWED_IMAGE_EXTENSIONS],
    },
    checkinOptionImage: {
        ...defaultParams,
    },
}