<template>
  <div>
    <div class="p-uploader-w">
      <div class="p-uploader__preview d-flex align-items-center">
        <div :class="['p-uploader__media-wrapper', {'compact': multiple}, orientation]" @click="openModal">
          <template v-if="isImage">
            <img class="p-uploader__img" ref="img" :src="fileUrl" alt="" @load="getImageOrientation">
          </template>
          <template v-else-if="isVideo">
            <video class="p-uploader__video" ref="video" @loadeddata="getVideoDuration()">
              <source :src="fileUrl">
              Your browser does not support HTML5 video.
            </video>
          </template>
          <template v-else>
            <!-- TODO: Put a placeholder in case the file doesn't have an extension -->
            PLACEHOLDER
          </template>
        </div>

        <div v-if="filename" class="p-uploader__info">
          <div class="p-uploader__file-name">{{ filename|truncate(50, '...') }}</div>
          <div class="p-uploader__file-size text-help">
            <template v-if="isImage">{{ getImgSizes() }}</template>
            <template v-else-if="isVideo">{{ videoDuration|formatDuration }}</template>
          </div>
        </div>
        <!-- dragger -->
        <div class="handle p-1 mr-3" v-if="sorting">
          <CIcon size="lg" name="cip-hamburger"/>
        </div>
        <!-- delete button -->
        <CButtonClose class="list-item-closer mr-2" v-if="allow_delete" @click.prevent="$emit('delete')">
          <CIcon name="cipClose" size="custom-size" width="15" />
        </CButtonClose>
      </div>


      <slot name="footer-preview"></slot>

      <CModal :title="filename" :show.sync="showModal" centered>
        <template v-if="isImage">
          <img class="img-fluid p-uploader__ph d-block mx-auto" :src="fileUrl" alt="">
        </template>
        <template v-else-if="isVideo">
          <video controls class="p-uploader__video">
            <source :src="fileUrl">
            Your browser does not support HTML5 video.
          </video>
        </template>
        <template #footer>
          <div class="d-flex justify-content-between align-items-center w-100">
            <template v-if="isImage">{{ getImgSizes() }}</template>
            <template v-else>{{videoDuration|formatDuration}}</template>
            <CButton color="secondary" @click="showModal = false">Close</CButton>
          </div>
        </template>
      </CModal>
    </div>
  </div>
</template>
<script>
import fileUtils from "@/domain/core/utils/fileUtils";

const orientationTypes = {
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  SQUARE: 'square'
};
export default {
  name: "PFilePreview",
  emit: ['delete'],
  props: {
    file: {
      type: [String],
      required: true
    },
    filename: {
      type: [String],
      required: false
    },
    allow_delete: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    sorting: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      orientation: null,
      videoDuration: null
    }
  },
  computed: {
    isImage() {
      return fileUtils.isImage(this.filename ?? this.file)
    },
    isVideo() {
      return fileUtils.isVideo(this.filename ?? this.file)
    },
    fileUrl() {
      if (!this.isImage && !this.isVideo) return null
      return this.file
    }
  },
  methods: {
    openModal() {
      if (!this.isImage && !this.isVideo)
        return
      this.showModal = true
    }, getImgSizes() {
      if (!this.isImage) return null
      if (!this.$refs.img) return null // Refs are not available until the component is mounted
      return `${this.$refs.img.naturalWidth}x${this.$refs.img.naturalHeight} px`
    }, getImageOrientation() {
      if (!this.isImage) return null
      if (!this.$refs.img) return null
      const landscapeThreshold = 2.0
      const aspectRatio = this.$refs.img.naturalWidth / this.$refs.img.naturalHeight
      // Check if the aspect ratio exceeds the landscape threshold
      if (aspectRatio > landscapeThreshold) {
        this.orientation = orientationTypes.LANDSCAPE
      } else if (this.$refs.img.naturalWidth === this.$refs.img.naturalHeight) {
        this.orientation = orientationTypes.SQUARE
      } else {
        this.orientation = orientationTypes.PORTRAIT
      }
    }, getVideoDuration() {
      if (!this.isVideo) return null
      if (!this.$refs.video) return null
      this.videoDuration = this.$refs.video.duration
    }
  }
}
</script>