<template>
  <CRow>
    <CCol>
      <CContainer :fluid="in_tab" class="p-0">
        <CRow class="mt-3">
          <CCol lg="5">
            <label class="mb-1 big" v-translate translate-context="event_wizard.label">Layout</label>
            <p v-translate translate-context="event_wizard.subtitle">
              Select how you want to arrange your logo and how it will be viewed by guests</p>
          </CCol>
          <CCol lg="7">
            <div class="scroller-wrap" ref="scrollerWrap">
              <div class="overflow-auto form-check-w"
                   ref="scroller"
                   @scroll="onScroll">
                <div class="d-flex" style="padding-top: 6px">
                  <div class="form-check form-check-inline form-check__layout"
                       v-for="setType in setTypes"
                       :key="setType.value"
                       @click.prevent.stop="onChangeLayoutType($event, setType.value)">
                    <CIcon name="cipCircleCheckFill" size="custom-size" width="18" class="check"
                           v-if="setType.value === form.type"/>
                    <label :for="form.type" class="form-check-label d-flex flex-column align-items-center">
                  <span class="img-wrap" :class="{'active': setType.value === form.type}">
                    <img
                        :src="require(`@/assets/images/event_wizard/step_logo/${setType.img}${setType.value === form.type ? '-on' : ''}.svg`)"
                        :alt="setType.text">
                       <CInputRadio :id="setType.value"
                                    :value="setType.value"
                                    class="d-none"
                                    custom
                                    :checked="setType.value === form.type"
                                    @update:checked="() => form.type = setType.value"/>
                    <span class="name">{{ setType.text }}</span>
                  </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end nav-scroller" v-if="layout.canScrollLeft || layout.canScrollRight">
              <div class="d-flex">
                <CLink @click="scroll('left')" :disabled="!layout.canScrollLeft" class="">
                  <CIcon name="cipChevronLeft" size="custom-size" width="20"/>
                </CLink>
                <CLink @click="scroll('right')" :disabled="!layout.canScrollRight" class="ml-1">
                  <CIcon name="cipChevronRight" size="custom-size" width="20"/>
                </CLink>
              </div>
            </div>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <SimpleLogoEditor
                :images="form.logos"
                :get_upload_intent="get_upload_intent"
                :get_upload_intent_public_url="get_upload_intent_public_url"
                :advanced="advanced"
                @save="onSaveEditor"
                @updatePreview="onUpdatePreviewEditor"
                v-if="form.type === 'simple_logo'"
            />
            <BarEditor
                :images="form.logos"
                :get_upload_intent="get_upload_intent"
                :get_upload_intent_public_url="get_upload_intent_public_url"
                @save="onSaveEditor"
                @updatePreview="onUpdatePreviewEditor"
                v-if="form.type === 'bar'"
            />
            <CustomEditor
                :images="form.logos"
                :get_upload_intent="get_upload_intent"
                :get_upload_intent_public_url="get_upload_intent_public_url"
                @save="onSaveEditor"
                @updatePreview="onUpdatePreviewEditor"
                v-if="form.type === 'custom'"
            />
          </CCol>
        </CRow>

        <CRow v-if="advanced">
          <CCol col="12" class="mb-2">
            <hr>
          </CCol>
          <CCol lg="5">
            <label class="mb-1 big" v-translate translate-context="event_wizard.label">Tags</label>
          </CCol>
          <CCol lg="7">
            <PTreeTagInput v-model="form.tags" :allowed_tags="available_tags" :multiple="true"/>
          </CCol>
        </CRow>
      </CContainer>

      <div class="event-wizard__footer">
        <CRow>
          <CCol>
            <div class="d-flex justify-content-end">
              <div class="mr-2">
                <CButton color="secondary" block @click="init" ref="reset-button"
                         v-translate translate-context="event_wizard">
                  Reset
                </CButton>
              </div>
              <div>
                <CButton color="primary" @click="onSave" block ref="save-button"
                         v-translate translate-context="event_wizard">
                  Save
                </CButton>
              </div>

            </div>
          </CCol>
        </CRow>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import BarEditor from "@/domain/logoSet/editor/BarEditor.vue";
import CustomEditor from "@/domain/logoSet/editor/CustomEditor.vue";
import SimpleLogoEditor from "@/domain/logoSet/editor/SimpleLogoEditor.vue";
import {cloneDeep} from "lodash";
import {translate} from "vue-gettext";
import PTreeTagInput from "@/domain/core/components/PTreeTagInput.vue";

const {pgettext: $pgettext} = translate

export default {
  name: "LogoSetEditor",
  components: {PTreeTagInput, CustomEditor, BarEditor, SimpleLogoEditor},
  props: {
    data: {
      type: Object,
      required: true
    },
    advanced: {
      type: Boolean,
      required: false,
      default: false
    },
    allow_empty: {
      type: Boolean,
      required: false,
      default: false
    },
    get_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
    available_tags: {
      type: Object,
      required: false,
    },
    in_tab: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    setTypes() {
      let types = [
        {value: null, img: 'none', text: $pgettext('event_wizard.set_types', 'No logo')},
        {value: 'simple_logo', img: 'simple_logo', text: $pgettext('event_wizard.set_types', 'Simple logo')},
        {value: 'bar', img: 'bar', text: $pgettext('event_wizard.set_types', 'Full width')},
        {value: 'custom', img: 'custom', text: $pgettext('event_wizard.set_types', 'Custom')},
      ]
      if (!this.allow_empty) types = types.filter(setType => setType.value !== 'none')
      if (!this.advanced && this.form.type !== 'custom') return types.filter(setType => setType.value !== 'custom')
      return types
    }
  },
  data() {
    return {
      loading: false,
      form: {
        type: null,
        tags: [],
        logos: []
      },
      empty: {
        type: 'simple_logo',
        tags: [],
        logos: []
      },
      layout: {
        canScrollLeft: false,
        canScrollRight: false,
        previousScrollLeft: 0 // Used to determine scroll direction
      }
    }
  },
  emits: ['save', 'updatePreview'],
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()

    this.onScroll({type: 'scroll'}, {isInitialState: true})
    window.addEventListener('resize', () => {
      this.onScroll({type: 'resize'}, {isInitialState: true})
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onScroll)
  },
  methods: {
    async init() {
      this.form = cloneDeep(this.data)
    },
    async onSave() {
      this.$emit('save', this.form)
    },
    onSaveEditor(logos) {
      this.form.logos = logos
    },
    onUpdatePreviewEditor(data) {
      this.$emit('updatePreview', data)
    },
    onChangeLayoutType(event, newType) {
      if (newType === 'custom') {
        this.form.type = newType
        return
      }
      let confirmMsg = this.$gettext('Changing the layout will reset every unsaved parameters, are you sure?')
      let isEmpty = !this.form.logos || this.form.logos.length === 0
      if (isEmpty || confirm(confirmMsg)) {
        this.form.logos = []
        this.form.type = newType
      }
      this.scrollToSelected(event)
    },
    scroll(direction) {
      const elScrollableWrap = this.$refs.scrollerWrap
      const elScrollable = this.$refs.scroller
      let scrollAmount = elScrollableWrap.offsetWidth * 0.5 // 50% of the available scroll width
      elScrollable.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth'
      })
    },
    onScroll($event, args = {isInitialState: false}) {
      const elScrollableWrap = this.$refs.scrollerWrap
      const elScrollable = this.$refs.scroller
      const tolerance = 10

      if (!elScrollable || !elScrollableWrap) return

      if ((elScrollable.scrollLeft !== this.layout.previousScrollLeft) || args.isInitialState) {
        this.layout.canScrollLeft = elScrollable.scrollLeft > tolerance
        this.layout.canScrollRight = elScrollable.scrollLeft + elScrollableWrap.offsetWidth < elScrollable.scrollWidth - tolerance

        this.layout.previousScrollLeft = elScrollable.scrollLeft
      }
    },
    scrollToSelected(event) {
      const elScrollableWrap = this.$refs.scrollerWrap
      const elScrollable = this.$refs.scroller
      const elSelected = event.currentTarget

      if (!elScrollable || !elScrollableWrap || !elSelected) return

      const selectedRect = elSelected.getBoundingClientRect()
      const scrollableRect = elScrollableWrap.getBoundingClientRect()

      const offset = selectedRect.left - scrollableRect.left
      console.log('offset', offset)
      const scrollLeft = elScrollable.scrollLeft + offset - (scrollableRect.width / 2) + (selectedRect.width / 2)

      elScrollable.scrollTo({
        left: scrollLeft,
        behavior: 'smooth'
      })
    }
  }
}
</script>