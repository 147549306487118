<template>
  <CRow>
    <CCol col="12" class="mb-2">
      <hr>
    </CCol>
    <CCol lg="5">
      <label class="mb-1 big" v-translate translate-context="event_wizard.bar_editor.title">Portrait <span
          class="text-help ml-2">
          <translate>Width: 1080px</translate>
        </span></label>
      <p>
        <translate translate-context="event_wizard.subtitle">Suitable for vertical format photos</translate>
      </p>
    </CCol>
    <CCol lg="7">
      <PUploader
          :params="uploadParams.copyright"
          :get_intent="get_upload_intent"
          :value.sync="form.portrait.logo"
          :help_text="$pgettext('uploader.help','Allowed formats: .jpg .png')"
          @uploaded="newLogoUploaded('portrait')"
      />
    </CCol>
    <CCol col="12" class="mb-2">
      <hr>
    </CCol>
    <CCol lg="5">
      <label class="mb-1 big" v-translate translate-context="event_wizard.bar_editor.title">Landscape <span
          class="text-help ml-2">
          <translate>Width: 1620px</translate>
        </span></label>
      <p>
        <translate translate-context="event_wizard.subtitle">Suitable for horizontal format photos</translate>
      </p>
    </CCol>
    <CCol lg="7">
      <PUploader
          :params="uploadParams.copyright"
          :get_intent="get_upload_intent"
          :value.sync="form.landscape.logo"
          :help_text="$pgettext('uploader.help','Allowed formats: .jpg .png')"
          @uploaded="newLogoUploaded('landscape')"
      />
    </CCol>
  </CRow>
</template>

<script>
import PUploader from "@/domain/core/components/PUploader.vue";
import {upload} from "@/domain/eventWizard/upload";
import {DELETE_KEY, isUploadIntent} from "@/domain/core/components/uploader";
import tagTypes from "@/domain/core/constant/tagTypes";

export default {
  name: "BarEditor",
  components: {PUploader},
  props: {
    images: {
      type: [Object, Array],
      required: false
    },
    get_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
  },
  emits: ['save', 'updatePreview'],
  computed: {
    uploadParams() {
      return upload
    },
  },
  data() {
    return {
      form: {
        portrait: {
          logo: null,
          id: null,
        },
        landscape: {
          logo: null,
          id: null,
        },
      },
      preview: {
        portrait: '',
        landscape: '',
      },
      default: {
        logo: null,
        id: null,
      }
    }
  },
  watch: {
    data: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.form.landscape = {...this.default}
      this.form.portrait = {...this.default}
      if (this.images.length <= 0) {
        this.previewFieldUpdated()
        return
      }
      for (let i = 0; i < this.images.length; i++) {
        const image = this.images[i]
        if (!image.tags || image.tags.length <= 0) continue
        if (image.tags.includes(tagTypes.media_types.landscape)) {
          this.form.landscape = {
            logo: image.logo,
            id: image.id,
          }
          this.preview.landscape = image.logo
        } else if (image.tags.includes(tagTypes.media_types.portrait)) {
          this.form.portrait = {
            logo: image.logo,
            id: image.id,
          }
          this.preview.portrait = image.logo
        }
      }
      this.previewFieldUpdated()
    },
    async newLogoUploaded(type) {
      await this.$nextTick()
      if (type === 'portrait')
        this.preview.portrait = await this.getPreviewUrl(this.form.portrait.logo)
      else
        this.preview.landscape = await this.getPreviewUrl(this.form.landscape.logo)
      this.previewFieldUpdated()
    },
    async getPreviewUrl(logo) {
      if (logo === DELETE_KEY)
        return ''
      return await this.get_upload_intent_public_url(logo)
    },
    previewFieldUpdated() {
      let images = []
      if (this.preview.landscape) {
        images.push({
          logo_intent: isUploadIntent(this.form.landscape.logo) ? this.form.landscape.logo : null,
          logo_id: isUploadIntent(this.form.landscape.logo) ? null : this.form.landscape.id,
          size: 'original',
          key: 'landscape',
          icon: 'landscape',
          width: 1620,
          height: 1080,
          bg_color: null,
          bg_opacity: 0,
        })
      }
      if (this.preview.portrait) {
        images.push({
          logo_intent: isUploadIntent(this.form.portrait.logo) ? this.form.portrait.logo : null,
          logo_id: isUploadIntent(this.form.portrait.logo) ? null : this.form.portrait.id,
          size: 'original',
          key: 'portrait',
          icon: 'portrait',
          width: 1080,
          height: 1620,
          bg_color: null,
          bg_opacity: 0,
        })
      }
      this.$emit('updatePreview', images)
      this.save()
    },
    save() {
      let images = []
      if (this.form.landscape.logo) {
        images.push({
          logo: this.form.landscape.logo,
          id: this.form.landscape.id,
          size: 'original',
          tags: [tagTypes.media_types.landscape],
          bg_color: null,
          bg_opacity: 0,
        })
      }
      if (this.form.portrait.logo) {
        images.push({
          logo: this.form.portrait.logo,
          id: this.form.portrait.id,
          size: 'original',
          tags: [tagTypes.media_types.portrait],
          bg_color: null,
          bg_opacity: 0,
        })
      }
      this.$emit('save', images)
    },

  }
}
</script>