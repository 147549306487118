<template>
  <CRow class="">
    <CCol sm="12" class="mb-4">
      <div class="d-flex align-items-center">
        <h4 class="mr-3" v-translate translate-context="event_wizard.label">Logo</h4>
        <div class="info">
          <translate>Allowed formats</translate>
          : .PNG - .JPG
        </div>
      </div>
      <PUploader
          :params="uploadParams.copyright"
          :get_intent="get_upload_intent"
          :value.sync="form.logo"
          @uploaded="newLogoUploaded"
      />
    </CCol>
    <CCol md="4" class="mb-4">
      <label>Logo position</label>
      <div class="btn-group-squared btn-group-toggle btn-group-squared__logo_position" style="max-width: 200px">
        <label class="btn btn-outline-secondary btn-toggle mb-2" v-for="(position, index) in logoPositions"
               :key="index"
               :class="{'active': position.value === form.position}" :style="position.style">
          <input type="radio" name="logo-size" :id="`option-${index}`"
                 v-model="form.position"
                 :value="position.value"
                 :checked="position.value === form.position"
                 @change="onSave">
          <CIcon size="xl" :name="position.iconName" class="m-0"/>
        </label>
      </div>
    </CCol>
    <CCol md="4" class="mb-4">
      <label v-translate translate-context="event_wizard.label">Logo size</label>
      <div class="btn-group-squared btn-group-squared__logo_sizes btn-group-toggle">
        <label class="btn btn-outline-secondary btn-toggle mb-2" v-for="(size, index) in logoSizes" :key="index"
               :class="{'active': size.value === form.size}">
          <input type="radio" name="logo-size"
                 :id="`option-${index}`"
                 v-model="form.size"
                 :value="size.value"
                 :checked="size.value === form.size"
                 @change="onSave">
          <CIcon size="custom-size" :width="size.iconSize" :height="size.iconSize" class="m-0" name="cipSquare"/>
        </label>
      </div>
    </CCol>
    <CCol md="4" class="mb-4">
      <label v-translate translate-context="event_wizard.label">Background color</label>
            <PColorPicker :color="form.bg_color"
                    :alpha="form.bg_opacity"
                    @change="onChangeColor"/>
    </CCol>
    <CCol sm="12" class="mb-4">
      <PTagInput v-model="form.tags" :placeholder="$pgettext('event_wizard.placeholder','Tags')" @input="onSave"/>
    </CCol>
    <CCol sm="12" class="mb-4 text-right">
      <CButton color="danger" @click="$emit('delete')">Delete media</CButton>
    </CCol>


  </CRow>
</template>

<script>
import {upload} from "@/domain/eventWizard/upload";
import {DELETE_KEY} from "@/domain/core/components/uploader";
import PUploader from "@/domain/core/components/PUploader.vue";
import PTagInput from "@/domain/core/components/PTagInput.vue";
import PColorPicker from "@/domain/core/components/PColorPicker.vue";

const logoSizes = [
  {
    iconSize: '8',
    value: 'small'
  },
  {
    iconSize: '18',
    value: 'medium'
  },
  {
    iconSize: '28',
    value: 'large'
  },
  {
    iconSize: '44',
    value: 'original'
  }
]

const logoPositions = [
  {
    iconName: 'cipTl',
    value: 'top-left'
  },
  {
    iconName: 'cipTc',
    value: 'top-center',
    style: 'padding: 0 4px 20px;'
  },
  {
    iconName: 'cipTr',
    value: 'top-right'
  },
  {
    iconName: 'cipBl',
    value: 'bottom-left'
  },
  {
    iconName: 'cipBc',
    value: 'bottom-center',
    style: 'padding: 20px 4px 0;'
  },
  {
    iconName: 'cipBr',
    value: 'bottom-right'
  },
]

export default {
  name: "SubLogo",
  components: {PColorPicker, PTagInput, PUploader},
  props: {
    media: {
      type: Object,
      required: false
    },
    get_upload_intent: {
      type: Function,
      required: true
    },
    get_upload_intent_public_url: {
      type: Function,
      required: true,
    },
  },
  emits: ['update'],
  computed: {
    uploadParams() {
      return upload
    },
    logoPositions() {
      return logoPositions
    },
    logoSizes() {
      return logoSizes
    },
  },
  data() {
    return {
      form: {
        logo: null,
        position: 'bottom-center',
        size: 'medium',
        bg_color: null,
        bg_opacity: 1,
        tags: []
      },
      logo_url: null,
    }
  },
  watch: {
    media: function () {
      this.init()
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.logo_url = ''
      this.form = {...this.media}
      if (this.form.logo && this.form.logo !== DELETE_KEY)
        this.logo_url = this.form.logo
    },
    async newLogoUploaded() {
      await this.$nextTick()
      if (this.form.logo === DELETE_KEY)
        this.logo_url = ''
      else {
        this.logo_url = await this.get_upload_intent_public_url(this.form.logo)
      }
      this.onSave()
    },
    onChangeColor(color){
      this.form.bg_color = color.hex
      this.form.bg_opacity = color.a * 100

      this.onSave()
    },
    onSave() {
      this.$emit('update', {
        ...this.form,
        logo_url: this.logo_url
      })
    },
  }
}
</script>