<template>
  <div class="cropper-navigation mr-2">
    <div class="cropper-navigation__wrapper">
      <div class="cropper-navigation__zoom-icon cropper-navigation__zoom-icon--left align-items-center">
        <CLink @click="onManualZoom(-.1)">
            <CIcon name="cipMagnifierMinus" size="custom-size" :height="20" style="vertical-align: top" />
          </CLink>
      </div>
      <div
        class="cropper-navigation__line-wrapper"
        ref="line"
        @mousedown="onStart"
        @touchstart="onStart"
      >
        <div class="cropper-navigation__line">
          <div class="cropper-navigation__fill" :style="{
							flexGrow: zoom
						}"></div>
          <div
            class="cropper-navigation__circle"
            :style="{
							left: `${((zoom * 100))}%`
						}"
          >
            <div class="cropper-navigation__inner-circle"></div>
          </div>
        </div>
      </div>

      <div class="cropper-navigation__zoom-icon cropper-navigation__zoom-icon--right">
        <CLink @click="onManualZoom(.1)">
            <CIcon name="cipMagnifierPlus" size="custom-size" :height="20" style="vertical-align: top" />
          </CLink>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PCropperNavigation',
  props: {
    zoom: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      focus: false
    };
  },
  mounted() {
    window.addEventListener("mouseup", this.onStop, { passive: false });
    window.addEventListener("mousemove", this.onDrag, { passive: false });
    window.addEventListener("touchmove", this.onDrag, { passive: false });
    window.addEventListener("touchend", this.onStop, { passive: false });
  },
  destroyed() {
    window.removeEventListener("mouseup", this.onStop);
    window.removeEventListener("mousemove", this.onDrag);
    window.removeEventListener("touchmove", this.onDrag);
    window.removeEventListener("touchend", this.onStop);
  },
  methods: {
    onManualZoom(value) {
      this.$emit('change', this.zoom + value)
    },
    onDrag(e) {
      if (this.focus) {
        const position = e.touches ? e.touches[0].clientX : e.clientX;
        const line = this.$refs.line;

        if (line) {
          const { left, width } = line.getBoundingClientRect();
          this.$emit(
            "change",
            Math.min(1, Math.max(0, position - left) / width)
          );
        }
        if (e.preventDefault) {
          e.preventDefault();
        }
      }
    },
    onStop() {
      this.focus = false;
    },
    onStart(e) {
      this.focus = true;
      this.onDrag(e);
    },
  }
};
</script>

